import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import 'rxjs/add/observable/interval';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PriceComponent implements OnInit {

  // Using Output EventEmitter
  @Output() priceFilters = new EventEmitter();

  // define min, max and range
  public minVoucherPriceRange1 = 152.10;
  public maxVoucherPriceRange1 = 969.00;
  public minVoucherPriceRange2 = 150.30;
  public maxVoucherPriceRange2 = 1000.00;
  public minPackagesPriceRange3 = 135.90;
  public maxPackagesPriceRange3 = 955.00;
  public minPackagesPriceRange4 = 164.05;
  public maxPackagesPriceRange4 = 953.00;
  public minPackagesPriceRange5 = 172.00;
  public maxPackagesPriceRange5 = 890.15;
  public range1 = [152.10 , 969.00];
  public range2 = [150.30 , 1000.00];
  public range3 = [135.90 , 955.00];
  public range4 = [164.05 , 953.00];
  public range5 = [172.00 , 890.15];
  public url: any;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() { }

  // priceRangeChanged
  priceChanged(event: any) {
      this.priceFilters.emit(event);
  }
}

import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NavDataService } from '@wg/shared/services/navdata.service';

@Component({
  selector: 'app-vouchers-categories',
  templateUrl: './vouchers-categories.component.html',
  styleUrls: ['./vouchers-categories.component.scss']
})
export class VouchersCategoriesComponent implements OnInit {

  public vouchersSubMenu: any;

  constructor(private navDataService: NavDataService) { }

  ngOnInit() {
      this.vouchersSubMenu = this.navDataService.vouchersSubMenu;

  // collapse toggle
    $('.collapse-block-title').on('click', function(e) {
        // tslint:disable-next-line:no-unused-expression
        e.preventDefault;
        const speed = 300;
        const thisItem = $(this).parent(),
          nextLevel = $(this).next('.collection-collapse-block-content');
        if (thisItem.hasClass('open')) {
          thisItem.removeClass('open');
          nextLevel.slideUp(speed);
        } else {
          thisItem.addClass('open');
          nextLevel.slideDown(speed);
        }
    });
  }

  // For mobile view
  public mobileFilterBack() {
     $('.collection-filter').css('left', '-365px');
  }

}

import { Component, OnInit } from '@angular/core';

import { CartService } from '../../shared/services/cart.service';
import { ProductsList } from '@wg/shared/classes/products-list';
import { ProductsService } from '../../shared/services/products.service';
import { CollectionService } from '@wg/shared/services/collection.service';

import * as $ from 'jquery';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NavDataService } from '@wg/shared/services/navdata.service';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {
  public url: any;
  public counter = 1;
  public product: any;
  public packagesSubMenu: any;
  public vouchersSubMenu: any;
  public dateFrom: any;
  public dateTo: any;
  public offerType: any;
  public vendor: any;
  public currentCategoryId: any;
  public currentMode: any;

  constructor(
    private collectionService: CollectionService,
    private cartService: CartService,
    public route: ActivatedRoute,
    private navDataService: NavDataService,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getProduct(params['productid']);
    });

    this.packagesSubMenu = this.navDataService.packagesSubMenu;
    this.vouchersSubMenu = this.navDataService.vouchersSubMenu;
  }

  getProduct(id) {
    this.collectionService.getProduct(id).subscribe((data: any) => {
      this.product = data.record;
      this.dateFrom = data.record.datefrom;
      this.dateTo = data.record.dateto;
      this.offerType = data.record.type;
      this.vendor = data.record.vendor;
      this.currentCategoryId = parseInt(data.record.category_id);
      this.currentMode = parseInt(data.record.mode);
    });
  }

  public increment() {
    this.counter += 1;
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }

  public mobileSidebar() {
    $('.collection-filter').css('left', '-15px');
  }

  public addToCart(product: any, quantity) {
    if (quantity === 0) {
      return false;
    }
    // tslint:disable-next-line:radix
    this.cartService.addToCart(product, parseInt(quantity));
  }
}

import { Injectable } from '@angular/core';
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import 'rxjs/add/operator/map';

import { registerUrl, loginUrl, socialUserLoginUrl, changePasswordUrl, forgetPasswordUrl, ChangePasswordResetUrl } from '../classes/urls';
import { headers } from '../classes/constant';
import { User } from '../classes/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public user: User = null;
  public token: string = null;
  public loggedIn: boolean;
  public currentUrl: string = undefined;
  public previousUrl: string = undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService
  ) {
    this.refreshCredentials();
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  // checking login statement
  isLoggedIn() {
    if (this.user === null) {
      return false;
    }
    return true;
  }

  refreshCredentials() {
    const token = this.cookie.get('WY_token');
    const user = this.cookie.get('WY_user');
    if (token && user) {
      this.token = this.cookie.get('WY_token');
      this.user = JSON.parse(this.cookie.get('WY_user'));
    }
  }

  // setting the user
  setUser(res) {
    this.user = res.user;
    this.cookie.put('WY_user', JSON.stringify(res.user));
    this.cookie.put('WY_token', res.token);
  }

  getToken() {
    return this.cookie.get('WY_token');
  }
  // saving the token (non social user)
  saveToken(token) {
    this.token = token;
    this.cookie.put('WY_token', token);
  }

  // saving the token (socail user)
  saveSocialToken(token) {
    this.token = token;
    this.cookie.put('WY_token', token);
  }

  // signin with Google and saving the token
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res: any) => {
      this.getSocialUser({
        platform: 1,
        platformid:res.id,
        name: this.user.name,
        email: this.user.email
      }).subscribe((response: any) => {
        this.saveSocialToken(response.token);
      });
      if (this.previousUrl === '/checkout') {
        this.router.navigate(['/checkout']);
      } else {
        this.router.navigate(['/my-account']);
      }
    });
  }

  // signin with Facebook and saving the token
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((res: any) => {
      this.getSocialUser({
        platform: 2,
        platformid: res.id,
        name: this.user.name,
        email: this.user.email
      }).subscribe((response: any) => {
        this.token = response.token;
        this.saveSocialToken(response.token);
      });
      if (this.previousUrl === '/checkout') {
        this.router.navigate(['/checkout']);
      } else {
        this.router.navigate(['/my-account']);
      }
    });
  }

  reset() {
    this.user = null;
    this.token = null;
    this.cookie.removeAll();
    // localStorage.removeItem('cartItem');
    window.location.replace('/');
  }

  // registering user
  public registerUser(obj) {
    return this.http.post(`${registerUrl}`, obj, headers).map((data: any) => {
      return data;
    });
  }

  // getting user (social)
  getSocialUser(obj) {
    return this.http.post(`${socialUserLoginUrl}`, obj);
  }

  // getting user (non social)
  getUser(obj) {
    return this.http.post(`${loginUrl}`, obj, headers).map((data: any) => {
      this.user = data.user;
      this.token = data.token;
      this.saveToken(data.token);
      if (this.previousUrl === '/checkout') {
        this.router.navigate(['/checkout']);
      } else {
        this.router.navigate(['/my-account']);
      }
      return data;
    });
  }

  // change password
  changePass(params): Observable<any> {
    return this.http.post(`${changePasswordUrl}`, params);
  }

  sendResetPasswordLink(data) {
    return this.http.post(`${forgetPasswordUrl}`, data)
  } 

  resetPassword(data) {
    return this.http.post(`${ChangePasswordResetUrl}`, data)
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoginAuthGaurdService implements CanActivate {

  constructor(
    private router: Router,
    private accountAuthenticationService: AuthenticationService
    ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.accountAuthenticationService.isLoggedIn()) {
        this.router.navigate(['/']);
        this.accountAuthenticationService.reset();
        return false;
      }
      return true;
  }
}

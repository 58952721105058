import { Component, OnInit } from '@angular/core';
// import { MENUITEMS, Menu } from './navbar-items';
import { Router, ActivatedRoute } from '@angular/router';
import { NavDataService } from '@wg/shared/services/navdata.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  // public menuItems: Menu[];
  public packagesSubMenu: any;
  public vouchersSubMenu: any;

  constructor(private navDataService: NavDataService) { }

  ngOnInit() {
      this.packagesSubMenu = this.navDataService.packagesSubMenu;
      this.vouchersSubMenu = this.navDataService.vouchersSubMenu;
    // this.menuItems = MENUITEMS.filter(menuItem => menuItem);
  }

}

//export const baseUrl = 'https://stag.wyldgrass.com/api';
export const baseUrl = 'http://localhost/wildgrass-backend/public/api';
export const iconsUrl = 'assets/images/main-categories/';
export const navDataUrl = `${baseUrl}/lookups/get`;

export const productsUrl = `${baseUrl}/products/get?id=`;

export const offersUrl = `${baseUrl}/product/get?id=`;

export const productTagUrl = `${baseUrl}/product/listbytag?tag=`;

export const imgIconsUrl = '../../../assets/data/icons-imags.json';

export const loginUrl = `${baseUrl}/auth/login`;

export const socialUserLoginUrl = `${baseUrl}/auth/loginwithsocial`;

export const registerUrl = `${baseUrl}/users/create`;

export const mainPageUrl = `${baseUrl}/homepage/get`;

export const featuredProductsUrl = 'assets/data/featured-products.json';

export const featuredVouchersUrl = 'assets/data/featured-vouchers.json';

export const contactUsUrl = `${baseUrl}/contactus/newmessage`;

export const createPaymentUrl = `${baseUrl}/payments/createpaymentlink`;

export const orderDetailsUrl = `${baseUrl}/users/orders/details?id=`;

export const invoicesListUrl = `${baseUrl}/users/invoices/list`;

export const invoicesDetailsUrl = `${baseUrl}/users/invoices/details?id=`;

export const changePasswordUrl = `${baseUrl}/users/changepassword`;

export const subscribeUrl = `${baseUrl}/newsletter/add`;

export const forgetPasswordUrl = `${baseUrl}/users/reset-password-request`;

export const ChangePasswordResetUrl = `${baseUrl}/users/change-password-reset`;
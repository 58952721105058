import { Component, OnInit } from '@angular/core';
import { NavDataService } from '@wg/shared/services/navdata.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-tags-categories',
  templateUrl: './tags-categories.component.html',
  styleUrls: ['./tags-categories.component.scss']
})
export class TagsCategoriesComponent implements OnInit {
  public items: any;
  constructor(private navDataService: NavDataService) {}

  ngOnInit() {
    this.navDataService.fetchImgIcons().subscribe(data => {
      this.items = data;
    });

    // collapse toggle
    $('.collapse-block-title').on('click', function(e) {
      // tslint:disable-next-line:no-unused-expression
      e.preventDefault;
      const speed = 300;
      const thisItem = $(this).parent(),
        nextLevel = $(this).next('.collection-collapse-block-content');
      if (thisItem.hasClass('open')) {
        thisItem.removeClass('open');
        nextLevel.slideUp(speed);
      } else {
        thisItem.addClass('open');
        nextLevel.slideDown(speed);
      }
    });
  }

  public mobileFilterBack() {
    $('.collection-filter').css('left', '-365px');
  }
}

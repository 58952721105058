import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { NavDataService } from '@wg/shared/services/navdata.service';
import { Observable, of, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LookupResolverService implements Resolve<any> {

  constructor(private navDataService:NavDataService ) { }

  resolve() {
       return this.navDataService.getLookups();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NavDataService } from '@wg/shared/services/navdata.service';
import { ProductsList } from '@wg/shared/classes/products-list';
import * as $ from 'jquery';
import { CartService } from '@wg/shared/services/cart.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-product-item-tag',
  templateUrl: './product-item-tag.component.html',
  styleUrls: ['./product-item-tag.component.scss'],
  animations: [  // angular animation
    trigger('Animation', [
      transition('* => fadeOut', [
        style({ opacity: 0.1 }),
        animate(2000, style({ opacity: 0.5 }))
      ]),
      transition('* => fadeIn', [
         style({ opacity: .2 }),
         animate(2000, style({ opacity: 0.8 }))
      ])
    ])
  ]
})
export class ProductItemTagComponent implements OnInit {
  public url: any;
  public products: ProductsList[];
  public filteredProducts: any[] = [];
  public finished = false;
  public animation: any;
  public items: any;
  public currentCategoryId: any;

  constructor(
    private navDataService: NavDataService,
    private cartService: CartService,
    public route: ActivatedRoute,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.currentCategoryId = params['tag'];
      this.getProductTag(params['tag']);
    });
  }

  getProductTag(tag) {
    this.filteredProducts = [];
    this.products = [];
    this.navDataService.getProductsTagList(tag).subscribe((data: any) => {
      this.products = data.list;
      this.filteredProducts = data.list;
    });
  }
  public updatePriceFilters(range: any) {
    const filteredProducts: any[] = [];
    this.products.filter((product: ProductsList) => {
      if (product.price >= range[0] && product.price <= range[1]) {
        filteredProducts.push(product); // push in array
      }
    });
    // debugger;
    this.filteredProducts = filteredProducts;
  }

  public mobileFilter() {
    $('.collection-filter').css('left', '-15px');
  }
  public addToCart(product: any, quantity) {
    quantity = 1;
    if (quantity === 0) {
      return false;
    }
    // tslint:disable-next-line:radix
    this.cartService.addToCart(product, parseInt(quantity));
  }

  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }
  public isFinished() {
    if (this.products.length) {
      this.finished = true;
    }
    if (!this.products.length) {
      this.animation === 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
      this.finished = false;
    }
  }
}

import { Routes } from '@angular/router';

import { MainComponent } from './main/main.component';
import { DemoComponent } from './demo/demo.component';
import { VouchersListComponent } from './shop/vouchers-list/vouchers-list.component';
import { ProductItemComponent } from './shop/product-item/product-item.component';
import { CartComponent } from './shop/product/cart/cart.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AccountAuthGaurdService } from './shared/services/account-auth-gaurd.service';
import { LoginAuthGaurdService } from './shared/services/login-auth-gaurd.service';
import { RegisterComponent } from './pages/register/register.component';
import { PackagesListComponent } from './shop/packages-list/packages-list.component';
import { CheckoutComponent } from './shop/product/checkout/checkout.component';
import { AboutComponent } from './shop/home-13/about/about.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { MyOrdersComponent } from './pages/my-orders/my-orders.component';
import { OrderSuccessComponent } from './pages/order-success/order-success.component';
import { OrderFailureComponent } from './pages/order-failure/order-failure.component';
import { InvoiceDetailsComponent } from './pages/invoice-details/invoice-details.component';
import { ProductItemTagComponent } from './shop/product-item-tag/product-item-tag.component';
import { VendorDetailsComponent } from './shop/vendor-details/vendor-details.component';
import { ChangePasswordRequestComponent } from './pages/change-password-request/change-password-request.component';
import { ChangePasswordResetComponent } from './pages/change-password-reset/change-password-reset.component';
import { LookupResolverService  } from "./shared/services/lookup-resolver.service";

export const rootRouterConfig: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'demo',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'demo',
  //   component: DemoComponent
  // },
  {
    path: '',
    component: MainComponent,
    resolve: { items: LookupResolverService },
    children: [
      {
        path: 'vouchers/:catid',
        component: VouchersListComponent
      },
      {
        path: 'packages/:packageid',
        component: PackagesListComponent
      },
      {
        path: 'offer/:productid',
        component: ProductItemComponent
      },
      {
        path: 'categories/:tag',
        component: ProductItemTagComponent
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginAuthGaurdService]
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'my-account',
        component: DashboardComponent,
        canActivate: [AccountAuthGaurdService]
      },
      {
        path: 'forget-password',
        component: ForgetPasswordComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'my-orders',
        component: MyOrdersComponent
      },
      {
        path: 'order-success/:orderid',
        component: OrderSuccessComponent
      },
      {
        path: 'order-failure/:orderid',
        component: OrderFailureComponent
      },
      {
        path: 'invoice-details/:invoiceid',
        component: InvoiceDetailsComponent
      },
      {
        path: 'cart',
        component: CartComponent
      },
      {
        path: 'checkout',
        component: CheckoutComponent
      },
      {
        path: 'about',
        component: AboutUsComponent
      },
      {
        path: 'terms',
        component: TermsComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },
      {
        path: 'vendor-details/:vendorid',
        component: VendorDetailsComponent
      },
      {
        path: 'home',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./pages/pages.module').then(m => m.PagesModule)
      },
      { path: 'reset-password', component: ChangePasswordRequestComponent },
      { path: 'change-password-reset', component: ChangePasswordResetComponent }
      // {
      //   path: 'blog',
      //   loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
      // }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

import { Component, OnInit } from '@angular/core';
import { OrderService } from '@wg/shared/services/order.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {

  public orders: any;
  public ordersLength: any;

  constructor(private orderService: OrderService) { }

  ngOnInit() {
    this.ordersLength = 0;
    this.orderService.getInvoicesList().subscribe(data => {
      this.orders = data;
      this.ordersLength = this.orders.length;
    }, error =>{
      console.log(error);
    });
  }
}

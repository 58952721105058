import { Component, OnInit } from '@angular/core';
import { Product } from '../../../../classes/product';
import { WishlistService } from '../../../../services/wishlist.service';
import { ProductsService } from '../../../../../shared/services/products.service';
import { Observable, of } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService, SocialUser } from 'angularx-social-login';
import { AuthenticationService } from '@wg/shared/services/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar-one.component.html',
  styleUrls: ['./topbar-one.component.scss']
})
export class TopbarOneComponent implements OnInit {
  public user: SocialUser;
  public loggedIn: boolean;
  public url: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService
  ) {
    if (event instanceof NavigationEnd) {
      this.router.navigated = false;
      this.url = event.url;
    }
    if (this.url === '/vouchers/1') {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return true;
      };
    } else if (this.url === 'vouchers/2') {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return true;
      };
    } else if (this.url === '/packages/3') {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return true;
      };
    } else if (this.url === '/packages/4') {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return true;
      };
    } else if (this.url === '/packages/5') {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return true;
      };
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      };
    }
    this.router.events.subscribe(event => {
      if (this.url === '/vouchers/1') {
        this.router.navigated = true;
      } else if (this.url === 'vouchers/2') {
        this.router.navigated = true;
      } else if (this.url === '/packages/3') {
        this.router.navigated = true;
      } else if (this.url === '/packages/4') {
        this.router.navigated = true;
      } else if (this.url === '/packages/5') {
        this.router.navigated = true;
      }
    });
  }

  ngOnInit() {
    this.user = this.authenticationService.user;
    this.loggedIn = this.user != null;
  }

  signOut(): void {
    if (
      this.authenticationService.signInWithFB ||
      this.authenticationService.signInWithGoogle
    ) {
      this.authService.signOut();
      this.router.navigate(['/']);
    }
    this.authenticationService.reset();
    this.router.navigate(['/']);
  }
}

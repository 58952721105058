import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../services/collection.service';

@Component({
  selector: 'app-main-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  public slider: any;
  public slideShowsUrl: any;
  // Slick slider config
  public sliderConfig: any = {
    autoplay: true,
    autoplaySpeed: 3000
  };

  constructor(private collectionService: CollectionService) {}

  ngOnInit() {
    this.slider = this.collectionService.slides;
    this.slideShowsUrl =
      'https://stag.wyldgrass.com/storage/uploads/slideshows/';
  }

  getUrl() {
    return this.slider[0].img;
  }
}

import { Component, OnInit } from '@angular/core';
import { SubscribeService } from '@wg/shared/services/subscribe.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {
  public loadingSpinner = false;
  public record: any = {};

  constructor(private subscribeService: SubscribeService) {}

  ngOnInit() {}

  subscribe() {
    this.loadingSpinner = true;
    let params = {};
    const fd = new FormData();
    fd.append('email', this.record.email);
    params = fd;

    this.subscribeService.subscribeToNewsLetter(params).subscribe(
      () => {
        Swal.fire('Success!', 'You subscribed to our Newsletter !', 'success');
        $('#subscribe-form').trigger('reset');
      },
      err => {
        this.loadingSpinner = false;
      },
      () => {
        this.loadingSpinner = false;
      }
    );
  }
}

import { Injectable } from '@angular/core';
import { Product } from '../classes/product';
import { CartItem } from '../classes/cart-item';
import { Order } from '../classes/order';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { createPaymentUrl, orderDetailsUrl, invoicesListUrl, invoicesDetailsUrl } from '../classes/urls';
import { headers } from '../classes/constant';
import { AuthenticationService } from './auth.service';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  // Array
  public OrderDetails;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) {}

  // Get order items
  public getOrderItems(): Order {
    return this.OrderDetails;
  }

  // Create order
  public createOrder(product: any, details: any, orderId: any, amount: any) {
    const item = {
      shippingDetails: details,
      product: product,
      orderId: orderId,
      totalAmount: amount
    };
    this.OrderDetails = item;
    this.router.navigate(['/home/checkout/success']);
  }

  createPaymentLink(params) {
    const token = this.authenticationService.token;
    if (token == null) {
      return;
    }

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.post(createPaymentUrl, params, headers);
  }

  getOrderDetail(id): Observable<any> {
    return this.http.get(`${orderDetailsUrl}${id}`);
  }

  getInvoicesList(): Observable<any> {
    return this.http.get(`${invoicesListUrl}`);
  }

  getInvoicesDetails(id):  Observable<any> {
    return this.http.get(`${invoicesDetailsUrl}${id}`);
  }
}

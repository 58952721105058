import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { Http, HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShopModule } from './shop/shop.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { rootRouterConfig } from './app.routes';

// login with facebook & google
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
// components
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { DemoComponent } from './demo/demo.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SliderComponent } from './main/slider/slider.component';
// import { ProductItemComponent } from './shop/product-item/product-item.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AccountAuthGaurdService } from './shared/services/account-auth-gaurd.service';
import { AuthenticationService } from './shared/services/auth.service';
import { LoginAuthGaurdService } from './shared/services/login-auth-gaurd.service';
import { RegisterComponent } from './pages/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CookieModule } from 'ngx-cookie';
import { CheckOtherProductsComponent } from './shop/check-other-products/check-other-products.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { MyOrdersComponent } from './pages/my-orders/my-orders.component';
import { OrderSuccessComponent } from './pages/order-success/order-success.component';
import { OrderFailureComponent } from './pages/order-failure/order-failure.component';
import { ContactUsService } from './shared/services/contact-us.service';
import { TokenInterceptorService } from './shared/services/token-interceptor.service';
import { InvoiceDetailsComponent } from './pages/invoice-details/invoice-details.component';
import { ChangePasswordResetComponent } from './pages/change-password-reset/change-password-reset.component';
import { ChangePasswordRequestComponent } from './pages/change-password-request/change-password-request.component';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      '902646451196-m7vqq8i3jqoquela8cpu3q3adqr5ne69.apps.googleusercontent.com'
    )
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('569349403624813')
  }
]);

export function provideConfig() {
  return config;
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DemoComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
    MyOrdersComponent,
    OrderSuccessComponent,
    OrderFailureComponent,
    DashboardComponent,
    RegisterComponent,
    SliderComponent,
    // ProductItemComponent,
    AboutUsComponent,
    TermsComponent,
    PrivacyComponent,
    ContactComponent,
    CheckOtherProductsComponent,
    InvoiceDetailsComponent,
    ChangePasswordResetComponent,
    ChangePasswordRequestComponent,
  ],
  imports: [
    SocialLoginModule,
    BrowserModule,
    HttpModule,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    ShopModule,
    SharedModule,
    HttpClientModule,
    SlickCarouselModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: false,
      enableHtml: true
    }),
    RouterModule.forRoot(rootRouterConfig, {
      useHash: false,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  providers: [
    AccountAuthGaurdService,
    AuthenticationService,
    LoginAuthGaurdService,
    ContactUsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { NavDataService } from '@wg/shared/services/navdata.service';

@Component({
  selector: 'app-why-we-choose',
  templateUrl: './why-we-choose.component.html',
  styleUrls: ['./why-we-choose.component.scss']
})
export class WhyWeChooseComponent implements OnInit {

  public packagesSubMenu: any;

  constructor(private navDataService: NavDataService) { }

  ngOnInit() {
    this.packagesSubMenu = this.navDataService.packagesSubMenu;
  }

}

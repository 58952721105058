import {
  Component,
  Inject,
  HostListener,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { LandingFixService } from '../../services/landing-fix.service';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '../../services/windows.service';
import { CartItem } from '../../classes/cart-item';
import { CartService } from '../../services/cart.service';
import { Observable, of } from 'rxjs';
import { MainCategoriesComponent } from '@wg/shared/main-categories/main-categories.component';
import { FeaturedProductsComponent } from '@wg/shared/featured-products/featured-products.component';

declare var $: any;

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderOneComponent implements OnInit {
  public shoppingCartItems: CartItem[] = [];
  public url: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window,
    private fix: LandingFixService,
    private cartService: CartService,
    private router: Router
  ) {
    this.cartService
      .getItems()
      .subscribe(
        shoppingCartItems => (this.shoppingCartItems = shoppingCartItems)
      );
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    $.getScript('assets/js/menu.js');
  }

  openNav() {
    this.fix.addNavFix();
  }

  closeNav() {
    this.fix.removeNavFix();
  }

  // @HostListener Decorator
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if (number >= 300) {
      this.document.getElementById('sticky').classList.add('fixed');
    } else {
      this.document.getElementById('sticky').classList.remove('fixed');
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { CollectionService } from '@wg/shared/services/collection.service';
import { ProductsList } from '@wg/shared/classes/products-list';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-check-other-products',
  templateUrl: './check-other-products.component.html',
  styleUrls: ['./check-other-products.component.scss']
})
export class CheckOtherProductsComponent implements OnInit {

  public products1: ProductsList[];
  public products2: ProductsList[];
  public products3: ProductsList[];
  public products4: ProductsList[];
  public products5: ProductsList[];
  public url: any;

  constructor(private collectionService: CollectionService, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    // this.fetchOffers1(1);
    // this.fetchOffers2(2);
    // this.fetchOffers3(3);
    // this.fetchOffers4(4);
    // this.fetchOffers5(5);
  }

  // fetchOffers1(id) {
  //   this.collectionService.fetchCollectionData(id).subscribe((data: any) => {
  //     this.products1 = data.list;
  //   });
  // }
  // fetchOffers2(id) {
  //   this.collectionService.fetchCollectionData(id).subscribe((data: any) => {
  //     this.products2 = data.list;
  //   });
  // }
  // fetchOffers3(id) {
  //   this.collectionService.fetchCollectionData(id).subscribe((data: any) => {
  //     this.products3 = data.list;
  //   });
  // }
  // fetchOffers4(id) {
  //   this.collectionService.fetchCollectionData(id).subscribe((data: any) => {
  //     this.products4 = data.list;
  //   });
  // }
  // fetchOffers5(id) {
  //   this.collectionService.fetchCollectionData(id).subscribe((data: any) => {
  //     this.products5 = data.list;
  //   });
  // }
}

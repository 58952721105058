import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@wg/shared/services/auth.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-change-password-request',
  templateUrl: './change-password-request.component.html',
  styleUrls: ['./change-password-request.component.scss']
})
export class ChangePasswordRequestComponent implements OnInit {

  resetForm: FormGroup;
  errors = null;
  successMsg = null;
  public loadingSpinner = false;

  constructor(
    public fb: FormBuilder,
    public authService: AuthenticationService
  ) {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit(): void { }

  onSubmit(){
    this.loadingSpinner = true;
    this.authService.sendResetPasswordLink(this.resetForm.value).subscribe(
      (result) => {
        Swal.fire({
          title: 'Success!',
          text: 'E-mail Sent Successfully to You',
          type: 'success',
          confirmButtonColor: '#5c9383'
        }).then(() => {
          $('#changePassForm').trigger('reset');
        });
      },(error) => {
        Swal.fire({
          title: 'Eror!',
          text: `${error.error.message}`,
          type: 'error',
          confirmButtonColor: '#5c9383'
        }).then(() => {
          $('#changePassForm').trigger('reset');
        });
        this.loadingSpinner = false;
      },() => {
        this.loadingSpinner = false;
      })
  }

}

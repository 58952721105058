import { Component, OnInit } from '@angular/core';
import { FeaturedProductsService } from '../services/featured-products.service';
import { CollectionService } from '../services/collection.service';
import { ProductsList } from '../classes/products-list';
import { CartService } from '../services/cart.service';

@Component({
  selector: 'app-featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['./featured-products.component.scss']
})
export class FeaturedProductsComponent implements OnInit {
  public products: any;
  public vouchers: any;
  public quantity: any;

  constructor(
    private collectionService: CollectionService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.products = this.collectionService.products;
    this.vouchers = this.collectionService.vouchers;
  }

  public addToCart(product: any, quantity) {
    quantity = 1;
    if (quantity === 0) {
      return false;
    }
    // tslint:disable-next-line:radix
    this.cartService.addToCart(product, parseInt(quantity));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { navDataUrl, imgIconsUrl, productTagUrl } from '../classes/urls';

@Injectable()
export class NavDataService {

  public packagesSubMenu: any = [];
  public vouchersSubMenu: any = [];
  public navData: any = [];

  constructor(private http: HttpClient) {

  }

  getLookups(){
    var mainObservable = Observable.create(observer => {
      this.fetchNavData().subscribe((data: any) => {
        this.navData.push(data);
        this.packagesSubMenu.push(data.packagesCats);
        this.vouchersSubMenu.push(data.vouchersCats);
        observer.next(data);
        observer.complete();
      });

    });
    return mainObservable;
  }

  fetchNavData(): Observable<Object> {
    return this.http.get(`${navDataUrl}`);
  }

  fetchImgIcons(): Observable<Object> {
    return this.http.get(`${imgIconsUrl}`);
  }

  getProductsTagList(tag): Observable<Object> {
    return this.http.get(`${productTagUrl}${tag}`);
  }
}

import { Component, OnInit } from '@angular/core';
import { OrderService } from '@wg/shared/services/order.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit {
  public url: any;
  public invoiceDetails: any = [];
  public codes: any;
  public vat: number;
  public subTotal: number;
  public total: any;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getInvoiceDetails(params['invoiceid']);
    });
  }

  getInvoiceDetails(id) {
    this.orderService.getInvoicesDetails(id).subscribe((data: any) => {
      data.items.map(item => {
        // storing items based on item type
        if (item.type === 'offer') {
          this.invoiceDetails.push(item);
        }
        if (item.type === 'vat') {
          this.vat = item.totalprice;
          this.vat = Math.round(this.vat);
          return false;
        }
      });

      // getting subtotal
      this.subTotal = this.invoiceDetails.reduce(
        (sum, elem) => (sum += 1 * elem.totalprice),
        0
      );
      // getting the total price
      this.total = this.subTotal  +this.vat;

      // rounding values
      this.total = Math.round(this.total);
      this.subTotal = Math.round(this.subTotal);

      // getting codes
      this.codes = data.codes;
    });
  }
}

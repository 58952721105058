import { Component, OnInit } from '@angular/core';
import { Product } from '../../../shared/classes/product';
import { CartItem } from '../../../shared/classes/cart-item';
// import { ProductsService } from '../../../shared/services/products.service';
import { CartService } from '../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { CollectionService } from '@wg/shared/services/collection.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public cartItems:   Observable<CartItem[]> = of([]);
  public shoppingCartItems: CartItem[] = [];
  public product: any;
  public counter = 1;

  constructor(private productsService: CollectionService,
    private cartService: CartService) { }

  ngOnInit() {
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.cartProduct();
  }

  cartProduct() {
    this.cartService.getItems().subscribe((data: any) => {
      this.product = data[0].product;
    });
  }
  // Increase Product Quantity
  public increment() {
    this.counter += 1;
  }

  // Decrease Product Quantity
  public decrement() {
    if (this.counter > 1) {
       this.counter -= 1;
    }
  }

  // Get Total
  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  public getVat(): Observable<number> {
    return this.cartService.getPriceVat();
  }

  public getTotalAfterVat(): Observable<number> {
    return this.cartService.getTotalAmountAfterVat();
  }

  // Remove cart items
  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }

}

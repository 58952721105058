import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parallax-banner-six',
  templateUrl: './parallax-banner.component.html',
  styleUrls: ['./parallax-banner.component.scss']
})
export class ParallaxBannerSixComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

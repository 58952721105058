import { Component, OnInit,Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    // angular animation
    trigger('Animation', [
      transition('* => fadeOut', [
        style({ opacity: 0.1 }),
        animate(2000, style({ opacity: 0.5 }))
      ]),
      transition('* => fadeIn', [
        style({ opacity: 0.2 }),
        animate(2000, style({ opacity: 0.8 }))
      ])
    ])
  ]
})
export class ListComponent implements OnInit {

  @Input() filteredProducts: any;
  @Input() products: any;
  public animation: any;
  public finished = false;

  constructor() { }

  ngOnInit() {
  }

  public isFinished() {
    if (this.products.length) {
      this.finished = true;
    }
    if (!this.products.length) {
      this.animation === 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
      this.finished = false;
    }
  }

  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }

}

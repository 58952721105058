import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { CollectionService } from '@wg/shared/services/collection.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.scss']
})
export class VendorDetailsComponent implements OnInit {
  public vendor: any;
  public slidesUrl: any;
  public vendorImage: any;
  public vendorName: any;
  public vendorDesc: any;
public url: any;

  constructor(private collectionService: CollectionService, private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getVendor(params['vendorid']);
    });
  }
  getVendor(id) {
    this.collectionService.getProduct(id).subscribe((data: any) => {
      this.vendor = data.record.vendor;
      this.slidesUrl = 'http://stag.wyldgrass.com/storage/uploads/vendors/';
      this.vendorImage = this.vendor.image;
      this.vendorName = this.vendor.name_en;
      this.vendorDesc = this.vendor.shortdesc_en;
    });
  }
  public mobileSidebar() {
    $('.collection-filter').css('left', '-15px');
  }
}

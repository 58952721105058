import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'angularx-social-login';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AuthenticationService } from '@wg/shared/services/auth.service';
import { User } from '@wg/shared/classes/user';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public user: User;
  public loggedIn: boolean;
  public loginRecord: any = {};
  public registerRecord: any = {};
  public loadingSpinner = false;
  public regLoadingSpinner = false;
  public confirmPassword = null;
  public notMatch: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authService.authState.subscribe(user => {
      if (user != null) {
        // Call API To Get User Token.
        // Send To API the ID of the Provider (Google/Facebook) , Api will process the incoming data and return a token
        this.authenticationService.setUser(user);
        this.router.navigate(['/my-account']);
      }
      this.loggedIn = this.authenticationService.user != null;
    });
  }

  signInWithGoogle(): void {
    this.authenticationService.signInWithGoogle();
  }

  signInWithFB(): void {
    this.authenticationService.signInWithFB();
  }

  signOut(): void {
    if (this.signInWithFB || this.signInWithGoogle) {
      this.authService.signOut();
    }
    this.authenticationService.reset();
    this.router.navigate(['/']);
  }

  registerUser() {
    this.regLoadingSpinner = true;
    let params = {};
    if (this.user == null) {
      params = this.registerRecord;
    } else {
      const fd = new FormData();
      fd.append('name', this.registerRecord.name);
      fd.append('lname', this.registerRecord.lname);
      fd.append('email', this.registerRecord.email);
      fd.append('password', this.registerRecord.password);
      fd.append('mobile', this.registerRecord.mobil);
    }

    if(this.registerRecord.password == this.confirmPassword){
      this.notMatch = false;
      this.authenticationService.registerUser(params).subscribe(
        (res) => {
          Swal.fire({
            title: 'Success!',
            text: 'Registeration Success!',
            type: 'success',
            confirmButtonColor: '#B0E095'
          }).then(() => {
            $('#regForm').trigger('reset');
          });
          
        },
        (err: any) => {
          const errbody = err.error.error;
         const error = errbody[Object.keys(errbody)[0]];
          if (errbody) {
            Swal.fire({
              title: 'Error!',
              text: error,
              type: 'error',
              confirmButtonColor: '#5c9383'
            }).then(() => {
              $('#regForm').trigger('reset');
            });
          }
          this.regLoadingSpinner = false;
        },
        () => {
          this.regLoadingSpinner = false;
        }
      );
    }else{
      this.notMatch = true;
      this.regLoadingSpinner = false;
    }
  }

  loginUser() {
    this.loadingSpinner = true;
    let params = {};
    if (this.user == null) {
      params = this.loginRecord;
    } else {
      const fd = new FormData();
      fd.append('email', this.loginRecord.email);
      fd.append('password', this.loginRecord.password);
      params = fd;
    }
    this.authenticationService.getUser(params).subscribe(
      res => {
        this.loggedIn = this.authenticationService.user != null;
        this.user = res.user;
        this.authenticationService.setUser(res);
        $('#logForm').trigger('reset');
      },
      err => {
        Swal.fire('Error!', 'Invalid Credentials, Please try again!', 'error');
        this.loadingSpinner = false;
      },
      () => {
        this.loadingSpinner = false;
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { CartService } from '../../shared/services/cart.service';
import { OrderService } from '../../shared/services/order.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CartItem } from '../../shared/classes/cart-item';
import { Observable, of } from 'rxjs';
import { User } from '@wg/shared/classes/user';
import { AuthenticationService } from '@wg/shared/services/auth.service';

@Component({
  selector: 'app-payment-order',
  templateUrl: './payment-order.component.html',
  styleUrls: ['./payment-order.component.scss']
})
export class PaymentOrderComponent implements OnInit {
  loadingPaymentFrame = true;
  errorPaymentFrame = false;
  paymentIframeSrc: SafeResourceUrl;
  public cartItems: Observable<CartItem[]> = of([]);
  public checkOutItems: CartItem[] = [];
  public user: User;
  public splitUserName: any;

  constructor(
    private cartService: CartService,
    private orderService: OrderService,
    public sanitizer: DomSanitizer,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(products => (this.checkOutItems = products));
    this.user = this.authService.user;
    this.splitUserName = this.user.name.split(' ');
    this.user.firstName = this.splitUserName[0];
    this.user.lastName = this.splitUserName[1];
    this.createPaymentLink();
  }

  createPaymentLink() {
    const offers = this.getOffersObjectForPayment();
    const billing = this.getBillingObjectForPayment();

    this.orderService
      .createPaymentLink({
        billing: billing,
        products: offers
      })
      .subscribe((response: any) => {
        console.log(response);
        this.paymentIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
          response
        );
        this.loadingPaymentFrame = false;
      },(error) =>{
      });
  }

  getOffersObjectForPayment() {
    const array = [];
    for (let i = 0; i < this.checkOutItems.length; i++) {
      array.push({
        id: this.checkOutItems[i].product.id,
        quantity: this.checkOutItems[i].quantity
      });
    }
    return array;
  }

  getBillingObjectForPayment() {
    return {
      first_name: this.user.firstName,
      last_name: this.user.lastName,
      email: this.user.email,
      phone_number: this.user.phone_number,
      street: this.user.street,
      city: this.user.city,
      country: this.user.country
    };
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { contactUsUrl } from '../classes/urls';

@Injectable()

export class ContactUsService {

    constructor(private http: HttpClient) {}

    sendContactMsg(obj): Observable<Object> {
        return this.http.post(`${contactUsUrl}`, obj);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { subscribeUrl } from '../classes/urls';

@Injectable()
export class SubscribeService {
  constructor(private http: HttpClient) {}

  subscribeToNewsLetter(obj): Observable<Object> {
    return this.http.post(`${subscribeUrl}`, obj);
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@wg/shared/services/auth.service';
import { User, PaymentUser } from '@wg/shared/classes/user';
import { Router } from '@angular/router';
import { OrderService } from '@wg/shared/services/order.service';
import { CartItem } from '@wg/shared/classes/cart-item';
import { Observable, of } from 'rxjs';
import { CartService } from '@wg/shared/services/cart.service';

@Component({
  selector: 'app-billing-data',
  templateUrl: './billing-data.component.html',
  styleUrls: ['./billing-data.component.scss']
})
export class BillingDataComponent implements OnInit {
  public user: User;
  public splitUserName: any;
  public checkOutItems: CartItem[] = [];
  public cartItems: Observable<CartItem[]> = of([]);
  public paymentUser: any = PaymentUser;
  public loggedIn: any;
  public loadingSpinner = false;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private orderService: OrderService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.loggedIn = this.authService.loggedIn;
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(products => (this.checkOutItems = products));
    this.user = this.authService.user;
    this.user.firstName = this.user.name;
    this.user.lastName = this.user.last_name;
  }

  proceedToPaymentPage() {
    this.router.navigate(['/home/payment-order']);
  }

  getOffersObjectForPayment() {
    const array = [];
    for (let i = 0; i < this.checkOutItems.length; i++) {
      array.push({
        id: this.checkOutItems[i].product.id,
        quantity: this.checkOutItems[i].quantity
      });
    }
    return array;
  }

  createPaymentLink() {
    this.loadingSpinner = true;
    const billing = (this.paymentUser = {
      first_name: this.user.firstName,
      last_name: this.user.lastName,
      email: this.user.email,
      phone_number: this.user.phone_number,
      street: this.user.street,
      city: this.user.city,
      country: this.user.country
    });
    const offers = this.getOffersObjectForPayment();

    this.orderService
      .createPaymentLink({
        billing: billing,
        products: offers
      })
      .subscribe(
        data => {
          this.router.navigate(['/home/payment-order']);
          console.log(data);
        },
        err => {
          console.log(err);
          this.loadingSpinner = false;
        },
        () => {
          this.loadingSpinner = false;
        }
      );
    localStorage.removeItem('cartItem');
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SocialUser, AuthService } from 'angularx-social-login';
import { AuthenticationService } from '@wg/shared/services/auth.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  public user: SocialUser;
  public loggedIn: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService
    ) {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      };
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.router.navigated = false;
        }
      });
    }

  ngOnInit() {
    this.user = this.authenticationService.user;
    this.loggedIn = (this.user != null);
    return this.user = this.authenticationService.user;
  }

  signOut(): void {
    if ( this.authenticationService.signInWithFB || this.authenticationService.signInWithGoogle ) {
      this.authService.signOut();
      this.router.navigate(['/']);
    }
    this.authenticationService.reset();
    this.router.navigate(['/']);
  }

// For mobile view
public openAccountMenu() {
  $('.dashboard-left').css('left', '0');
}

public mobileFilterBack() {
 $('.dashboard-left').css('left', '-365px');
}
}

import { Component, OnInit } from '@angular/core';
import { ContactUsService } from '@wg/shared/services/contact-us.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public record: any = {};
  public contactMsg: any = null;
  public loadingSpinner = false;

  constructor(private contactUsService: ContactUsService) {}

  ngOnInit() {}

  sendContactMsg() {
    this.loadingSpinner = true;
    let params = {};
    if (this.contactMsg == null) {
      params = this.record;
    } else {
      const formData = new FormData();
      formData.append('fname', this.record.fname);
      formData.append('lname', this.record.lname);
      formData.append('email', this.record.email);
      formData.append('phonenumber', this.record.phonenumber);
      formData.append('message', this.record.message);
    }
    this.contactUsService.sendContactMsg(params).subscribe(
      res => {
        Swal.fire({
          title: 'Success!',
          text: 'Your message sent successfully!',
          type: 'success',
          confirmButtonColor: '#5c9383'
        }).then(() => {
          $('#contactForm').trigger('reset');
        });
      },
      err => {
        this.loadingSpinner = false;
      },
      () => {
        this.loadingSpinner = false;
      }
    );
  }
}

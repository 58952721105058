import { Component, OnInit } from '@angular/core';
import { NavDataService } from '../services/navdata.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CollectionService } from '../services/collection.service';
import {  iconsUrl } from '@wg/shared/classes/urls';
@Component({
  selector: 'app-main-categories',
  templateUrl: './main-categories.component.html',
  styleUrls: ['./main-categories.component.scss']
})
export class MainCategoriesComponent implements OnInit {
  public record: any = [];
  public iconPath: any = iconsUrl;
  constructor(private navDataService: NavDataService) {}

  ngOnInit() {
    this.record = this.navDataService.vouchersSubMenu[0];
  }
}

import { Component, OnInit } from '@angular/core';
import { NavDataService } from '@wg/shared/services/navdata.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public vouchersSubMenu: any;

  constructor(private navDataService: NavDataService) { }

  ngOnInit() {
    this.vouchersSubMenu = this.navDataService.vouchersSubMenu;
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { productsUrl, offersUrl, mainPageUrl } from '../classes/urls';
import { ProductsList } from '../classes/products-list';
import { map, filter, scan } from 'rxjs/operators';

@Injectable()
export class CollectionService {
  public products: any = [];
  public vouchers: any = [];
  public slides: any = [];

  constructor(private http: HttpClient) {
    this.getMainData().subscribe((data: any) => {
      this.products.push(data.featuredPackages);
      this.vouchers.push(data.featuredVouchers);
      this.slides.push(data.slideshows);
    });
  }

  fetchCollectionData(id): Observable<any> {
    return this.http.get(`${productsUrl}${id}`);
  }

  public getProduct(id): Observable<any> {
    return this.http.get(`${offersUrl}${id}`);
  }

  public getMainData(): Observable<any> {
    return this.http.get(`${mainPageUrl}`);
  }

  //       public getProductByCategory(category: string): Observable<ProductsList[]> {
  //         return this.fetchCollectionData().pipe(map(items =>
  //            items.filter((item: ProductsList) => {
  //              if (category === 'all') {
  //               return item;
  //             } else {
  //                 return item.category === category;
  //             }
  //            })
  //          ));
  //       }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@wg/shared/services/auth.service';
import { User } from '@wg/shared/classes/user';
import * as $ from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public user: User;
  public record: any = {};
  public loadingSpinner = false;

  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    this.user = this.authService.user;
  }

  changePassword() {
    this.loadingSpinner = true;
    let params = {};
    const fd = new FormData();
    fd.append('oldpass', this.record.oldpass);
    fd.append('newpass', this.record.newpass);
    fd.append('confirmpass', this.record.confirmpass);
    params = fd;
    this.authService.changePass(params).subscribe(
      () => {
        Swal.fire({
          title: 'Success!',
          text: 'Password Changed successfully!',
          type: 'success',
          confirmButtonColor: '#5c9383'
        }).then(() => {
          $('#changePassForm').trigger('reset');
        });
      },
      err => {
        Swal.fire({
          title: 'Eror!',
          text: `${err.error.error}`,
          type: 'error',
          confirmButtonColor: '#5c9383'
        }).then(() => {
          $('#changePassForm').trigger('reset');
        });
        this.loadingSpinner = false;
      },
      () => {
        this.loadingSpinner = false;
      }
    );
  }
}

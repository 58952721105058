import { Component, OnInit, OnChanges, Input } from "@angular/core";
import { NavDataService } from "@wg/shared/services/navdata.service";
import { iconsUrl } from "@wg/shared/classes/urls";
@Component({
  selector: "app-wg-breadcrump",
  templateUrl: "./wg-breadcrump.component.html",
  styleUrls: ["./wg-breadcrump.component.scss"]
})
export class WgBreadcrumpComponent implements OnInit, OnChanges {
  @Input() id;
  @Input() mode;
  @Input() offerMode;
  @Input() title;
  public mainTitle: any;
  public breadTitle: any;
  public breadColor: any;
  public breadIcon: any;
  public iconPath: any = iconsUrl;
  public vouchersSubMenu: any;
  constructor(private navDataService: NavDataService) {}

  ngOnInit() {
    if (typeof this.id === "undefined" || this.id === "" || this.id === null) {
      return;
    }
      this.updateMainTitle();
  }
  ngOnChanges() {
    if (typeof this.id === "undefined" || this.id === "" || this.id === null) {
      return;
    }
    this.updateMainTitle();
  }
  updateMainTitle() {
    switch (parseInt(this.mode)) {
      case 1:
        this.mainTitle = "Packages";
        this.setBreadMetaDataFromCatId(this.id);
        break;
      case 2:
        this.mainTitle = "Vouchers";
        this.setBreadMetaDataFromCatId(this.id);
        break;
      case 3:
        this.mainTitle = "Categories";
        this.setBreadMetaDataFromCatTag(this.id);
        break;
      case 4:
        this.mainTitle = "About Us";
        break;
      case 5:
        this.mainTitle = "Contact Us";
        break;
      case 6:
        this.breadTitle = this.title;
        this.setBreadMetaDataForLevel2(this.id);
        break;
    }
  }

  setBreadMetaDataFromCatId(id){
    if (this.mode == 1) {
      this.vouchersSubMenu = this.navDataService.packagesSubMenu[0];
    }
    if (this.mode == 2) {
      this.vouchersSubMenu = this.navDataService.vouchersSubMenu[0];
    }
    for (var i = 0; i < this.vouchersSubMenu.length; i++) {
      if (this.vouchersSubMenu[i]["id"] == id) {
          this.displayBreadMetaData(this.vouchersSubMenu[i]);
          break;
      }
    }
  }
  setBreadMetaDataFromCatTag(tag){
    this.vouchersSubMenu = this.navDataService.vouchersSubMenu[0];
    for (var i = 0; i < this.vouchersSubMenu.length; i++) {
      if (this.vouchersSubMenu[i]["tag"] == tag) {
          this.displayBreadMetaData(this.vouchersSubMenu[i]);
        break;
      }
    }
  }
  setBreadMetaDataForLevel2(id){
    if (this.offerMode == 1) {
      this.vouchersSubMenu = this.navDataService.packagesSubMenu[0];
      this.mainTitle = "Packages / "+this.title;
    }
    if (this.offerMode == 2) {
      this.vouchersSubMenu = this.navDataService.vouchersSubMenu[0];
      this.mainTitle = "Vouchers / "+this.title;
    }
    for (var i = 0; i < this.vouchersSubMenu.length; i++) {
      if (this.vouchersSubMenu[i]["id"] == id) {
          this.breadColor = this.vouchersSubMenu[i]["color"];
          this.breadIcon = this.vouchersSubMenu[i]["icon"];
          break;
      }
    }
  }

  displayBreadMetaData(category) {
    this.breadTitle = category["name_en"];
    this.breadColor = category["color"];
    this.breadIcon = category["icon"];
  }
}

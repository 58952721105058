import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@wg/shared/services/auth.service';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-change-password-reset',
  templateUrl: './change-password-reset.component.html',
  styleUrls: ['./change-password-reset.component.scss']
})
export class ChangePasswordResetComponent implements OnInit {

  changePasswordForm: FormGroup;
  errors = null;
  public loadingSpinner = false;

  constructor(
    public fb: FormBuilder,
    route: ActivatedRoute,
    public authService: AuthenticationService,
  ) {
    this.changePasswordForm = this.fb.group({
      email: [''],
      password: ['admin123'],
      password_confirmation: ['admin123'],
      passwordToken: ['']
    })
    route.queryParams.subscribe((params) => {
      this.changePasswordForm.controls['passwordToken'].setValue(params['token']);
    })
  }

  ngOnInit(): void {

  }

  onSubmit(){ 
    this.loadingSpinner = true;
    this.authService.resetPassword(this.changePasswordForm.value).subscribe(
      result => {
        Swal.fire({
          title: 'Success!',
          text: 'Password Changed successfully!',
          type: 'success',
          confirmButtonColor: '#5c9383'
        }).then(() => {
          $('#changePassForm').trigger('reset');
        });
        this.changePasswordForm.reset();
      },
      error => {
        Swal.fire({
          title: 'Eror!',
          text: `${error.error.message}`,
          type: 'error',
          confirmButtonColor: '#5c9383'
        }).then(() => {
          $('#changePassForm').trigger('reset');
        });
        this.loadingSpinner = false;
        this.handleError(error);
      },
      () => {
        this.loadingSpinner = false;
      }
    );
  }

  handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
      } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  PayPalConfig,
  PayPalEnvironment,
  PayPalIntegrationType
} from 'ngx-paypal';
// import {  IPayPalConfig,  ICreateOrderRequest } from 'ngx-paypal';
import { CartItem } from '../../../shared/classes/cart-item';
import { ProductsService } from '../../../shared/services/products.service';
import { CartService } from '../../../shared/services/cart.service';
import { OrderService } from '../../../shared/services/order.service';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@wg/shared/services/auth.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  // form group
  public checkoutForm: FormGroup;
  public cartItems: Observable<CartItem[]> = of([]);
  public checkOutItems: CartItem[] = [];
  public orderDetails: any[] = [];
  public amount: number;
  public payPalConfig?: PayPalConfig;
  public loggedIn: any;

  // Form Validator
  constructor(
    private fb: FormBuilder,
    private cartService: CartService,
    public productsService: ProductsService,
    private orderService: OrderService,
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.checkoutForm = this.fb.group({
      firstname: [
        '',
        [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]
      ],
      lastname: [
        '',
        [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]
      ],
      phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required, Validators.maxLength(50)]],
      country: ['', Validators.required],
      town: ['', Validators.required],
      state: ['', Validators.required],
      postalcode: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(products => (this.checkOutItems = products));
    this.getTotal().subscribe(amount => (this.amount = amount));
    this.initConfig();
    this.loggedIn = this.authService.isLoggedIn();
  }

  // Get sub Total
  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  public getVat(): Observable<number> {
    return this.cartService.getPriceVat();
  }

  public getTotalAfterVat(): Observable<number> {
    return this.cartService.getTotalAmountAfterVat();
  }

  // stripe payment gateway
  stripeCheckout() {
    const handler = (<any>window).StripeCheckout.configure({
      key: 'PUBLISHBLE_KEY', // publishble key
      locale: 'auto',
      token: (token: any) => {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        this.orderService.createOrder(
          this.checkOutItems,
          this.checkoutForm.value,
          token.id,
          this.amount
        );
      }
    });
    handler.open({
      name: 'Multikart',
      description: 'Online Fashion Store',
      amount: this.amount * 100
    });
  }

  // Paypal payment gateway
  private initConfig(): void {
    this.payPalConfig = new PayPalConfig(
      PayPalIntegrationType.ClientSideREST,
      PayPalEnvironment.Sandbox,
      {
        commit: true,
        client: {
          sandbox: 'CLIENT_ID' // client Id
        },
        button: {
          label: 'paypal',
          size: 'small', // small | medium | large | responsive
          shape: 'rect' // pill | rect
          // color: 'blue',   // gold | blue | silver | black
          // tagline: false
        },
        onPaymentComplete: (data, actions) => {
          this.orderService.createOrder(
            this.checkOutItems,
            this.checkoutForm.value,
            data.orderID,
            this.amount
          );
        },
        onCancel: (data, actions) => {
        },
        onError: err => {
        },
        transactions: [
          {
            amount: {
              currency: this.productsService.currency,
              total: this.amount
            }
          }
        ]
      }
    );
  }

  proceedToBillingDetailsPage() {
    if (this.loggedIn) {
      this.router.navigate(['/home/billing-data']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}

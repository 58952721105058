import { Component, Input, OnInit, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { ProductsList } from '@wg/shared/classes/products-list';
import { CollectionService } from '@wg/shared/services/collection.service';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import * as $ from 'jquery';
import { CartService } from '@wg/shared/services/cart.service';
import { CartItem } from '@wg/shared/classes/cart-item';
import { NavDataService } from '@wg/shared/services/navdata.service';

@Component({
  selector: 'app-packages-list',
  templateUrl: './packages-list.component.html',
  styleUrls: ['./packages-list.component.scss'],
})
export class PackagesListComponent implements OnInit {
  public shoppingCartItems: CartItem[] = [];
  public products: ProductsList[];
  public filteredProducts: any[] = [];
  public finished = false;
  public animation: any;
  public cartProduct: any;
  public productsPrice: any;
  public url: any;
  public packagesSubMenu: any;
    public currentCategoryId : number;

  constructor(
    private collectionService: CollectionService,
    private route: ActivatedRoute,
    private cartService: CartService,
    private router: Router,
    private navDataService: NavDataService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.currentCategoryId = params['packageid'];
      this.fetchOffers(params['packageid']);
    });

    this.packagesSubMenu = this.navDataService.packagesSubMenu;
  }

  fetchOffers(id) {
    this.filteredProducts = [];
    this.products = [];
    this.collectionService.fetchCollectionData(id).subscribe((data: any) => {
      this.products = data.list;
      this.filteredProducts = data.list;
    });
  }

  public addToCart(product: any, quantity) {
    quantity = 1;
    if (quantity === 0) {
      return false;
    }
    // tslint:disable-next-line:radix
    this.cartService.addToCart(product, parseInt(quantity));
  }

  updateTagFilters() {}

  public updatePriceFilters(range: any) {
    const filteredProducts: any[] = [];
    this.products.filter((product: ProductsList) => {
      if (product.price >= range[0] && product.price <= range[1]) {
        filteredProducts.push(product); // push in array
      }
    });
    // debugger;
    this.filteredProducts = filteredProducts;
  }

  public mobileFilter() {
    $('.collection-filter').css('left', '-15px');
  }

  filterItems() {}

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { OrderService } from '@wg/shared/services/order.service';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent implements OnInit {
  public url: any;
  public token: string = null;
  public items: any = [];
  public codes: any;
  public vat: number;
  public subTotal: number;
  public total: any;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getOrderDetail(params['orderid']);
    });
  }

  getOrderDetail(id) {
    this.orderService.getOrderDetail(id).subscribe(data => {
      data.items.map(item => {
        // storing items based on item type
        if (item.type === 'offer') {
          this.items.push(item);
        }
        if (item.type === 'vat') {
          this.vat = item.totalprice;
          this.vat = Math.round(this.vat);
          return false;
        }
      });
      // getting subtotal
      this.subTotal = this.items.reduce(
        (sum, elem) => (sum += 1 * elem.totalprice),
        0
      );
      // getting the total price
      this.total = this.subTotal + this.vat;

      // rounding values
      this.total = Math.round(this.total);
      this.subTotal = Math.round(this.subTotal);
      this.codes = data.codes;
    });
  }
}
